import WhatsappIcon from "@/public/assets/icons/social/whatsApp.png"
import useOutsideAlerters from "@/src/elections/business-layer/hooks/useOutsideAlerters"
import { SocialShareEventProps } from "@/src/elections/business-layer/types"
import Copy from "@/src/sb/components/icon/Copy"
import Envelope from "@/src/sb/components/icon/Envelope"
import Facebook from "@/src/sb/components/icon/Facebook"
import Linkedin from "@/src/sb/components/icon/Linkedin"
import Share from "@/src/sb/components/icon/Share"
import Telegram from "@/src/sb/components/icon/Telegram"
import Twitter from "@/src/sb/components/icon/Twitter"
import { useWindowSize } from "@/src/sb/hooks/useWindowSize"
import classNames from "classnames"
import ClipboardJS from "clipboard"
import Image from "next/legacy/image"
import { useEffect, useRef, useState } from "react"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share"

export type SocialShareCardProps = {
  title: string
  url: string
  onClickShare?: (media: SocialShareEventProps["type"]) => void
  verticalArrangement?: boolean
  expanded?: boolean
  socialIconSize?: number
  buttons?: {
    linkedin?: boolean
    facebook?: boolean
    twitter?: boolean
    telegram?: boolean
    whatsapp?: boolean
    email?: boolean
    copy?: boolean
    "web-share"?: boolean
  }
}

export const SocialShareCard = ({
  title,
  url,
  onClickShare,
  verticalArrangement,
  expanded,
  socialIconSize = 24,
  buttons = {}
}: SocialShareCardProps) => {
  const isTablet = useWindowSize()[1] < 1024
  const [showShareMedias, setShowShareMedias] = useState(false)
  const [showCopied, setShowCopied] = useState(false)
  const shareCard = useRef<Array<{ el: HTMLElement | null } | null>>([])
  const showCopiedHandler = useRef<NodeJS.Timeout>()

  const mediaState: SocialShareCardProps["buttons"] = {
    linkedin: true,
    facebook: true,
    twitter: true,
    telegram: true,
    whatsapp: true,
    email: true,
    copy: true,
    "web-share": true,
    ...buttons
  }

  useOutsideAlerters(shareCard, () => {
    setShowShareMedias(false)
  })

  const handleSocialShareClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({ title: title, text: title, url: url })
        onClickShare?.("web-share")
      } catch (e) {
        setShowShareMedias(true)
      }
    } else {
      setShowShareMedias(!showShareMedias)
    }
  }

  useEffect(() => {
    const clpbrd = new ClipboardJS("#pageLinkCopyBtn", {
      text() {
        return url
      }
    })

    clpbrd.on("success", (e) => {
      setShowCopied(true)
      if (showCopiedHandler.current) clearTimeout(showCopiedHandler.current)
      showCopiedHandler.current = setTimeout(() => {
        setShowCopied(false)
      }, 2000)
      e.clearSelection()
    })

    return () => {
      clpbrd.destroy()
      if (showCopiedHandler.current) clearTimeout(showCopiedHandler.current)
    }
  }, [url])

  const expandMedia = expanded || showShareMedias || !mediaState["web-share"]
  return (
    <section
      ref={(el) => (shareCard.current[0] = { el })}
      className={classNames(
        "lg:bg-transparent p-4 lg:p-0 rounded-full",
        "inline-flex justify-center items-center transition-all overflow-hidden",
        "lg:overflow-visible shadow lg:shadow-none dark:bg-[#1A1D21]",
        [
          verticalArrangement ? "flex-col" : "flex-row-reverse gap-4",
          expandMedia ? "bg-white" : "bg-white/[.90]"
        ]
      )}
      style={{
        height:
          (expandMedia || !isTablet) && verticalArrangement
            ? 40 *
                Object.keys(mediaState).filter((media: string): boolean =>
                  Boolean(
                    mediaState[media as keyof SocialShareCardProps["buttons"]]
                  )
                ).length +
              10
            : 54,
        width:
          (expandMedia || !isTablet) && !verticalArrangement
            ? 36 *
                Object.keys(mediaState).filter((media: string): boolean =>
                  Boolean(
                    mediaState[media as keyof SocialShareCardProps["buttons"]]
                  )
                ).length +
              10
            : 54
      }}
    >
      <ul
        className={classNames(
          {
            hidden:
              isTablet &&
              !showShareMedias &&
              !expanded &&
              mediaState["web-share"]
          },
          [
            verticalArrangement
              ? `space-y-3 ${mediaState["web-share"] ? "mb-2.5" : ""}`
              : "space-x-3 flex items-center"
          ]
        )}
      >
        {mediaState.facebook ? (
          <li>
            <FacebookShareButton
              className={!verticalArrangement ? "flex items-center" : ""}
              title={title}
              url={url}
              onClick={() => onClickShare?.("facebook")}
            >
              <Facebook size={socialIconSize} />
            </FacebookShareButton>
          </li>
        ) : null}
        {mediaState.linkedin ? (
          <li>
            <LinkedinShareButton
              className={!verticalArrangement ? "flex items-center" : ""}
              title={title}
              url={url}
              onClick={() => onClickShare?.("linkedin")}
            >
              <Linkedin size={socialIconSize} />
            </LinkedinShareButton>
          </li>
        ) : null}
        {mediaState.twitter ? (
          <li>
            <TwitterShareButton
              className={!verticalArrangement ? "flex items-center" : ""}
              title={title}
              url={url}
              onClick={() => onClickShare?.("twitter")}
            >
              <Twitter size={socialIconSize} />
            </TwitterShareButton>
          </li>
        ) : null}
        {mediaState.email ? (
          <li>
            <a
              className={!verticalArrangement ? "flex items-center" : ""}
              href={`mailto:?subject=${title}&body=${title} - ${url}`}
              onClick={() => onClickShare?.("email")}
            >
              <Envelope size={socialIconSize} />
            </a>
          </li>
        ) : null}
        {mediaState.telegram ? (
          <li>
            <TelegramShareButton
              className={!verticalArrangement ? "flex items-center" : ""}
              title={title}
              url={url}
              onClick={() => onClickShare?.("telegram")}
            >
              <Telegram size={socialIconSize} />
            </TelegramShareButton>
          </li>
        ) : null}
        {mediaState.whatsapp ? (
          <li>
            <WhatsappShareButton
              className={!verticalArrangement ? "flex items-center" : ""}
              title={title}
              url={url}
              onClick={() => onClickShare?.("whatsapp")}
            >
              <Image
                src={WhatsappIcon}
                width={socialIconSize}
                height={socialIconSize}
                alt="WA"
              />
            </WhatsappShareButton>
          </li>
        ) : null}
        {mediaState.copy ? (
          <li>
            <button
              id="pageLinkCopyBtn"
              className={classNames(
                "relative",
                !verticalArrangement ? "flex items-center" : ""
              )}
              onClick={() => onClickShare?.("copy")}
            >
              <Copy size={socialIconSize} />
              <span
                className={classNames(
                  "absolute translate-x-1/2 right-1/2 bg-stears_black",
                  "text-white text-xs p-1 transition-all duration-700",
                  [showCopied ? "opacity-100" : "opacity-0"]
                )}
              >
                Copied!
              </span>
            </button>
          </li>
        ) : null}
      </ul>

      {mediaState["web-share"] ? (
        <button
          className={"h-5 w-5 lg:hidden"}
          onClick={handleSocialShareClick}
        >
          <Share size={20} />
        </button>
      ) : null}
    </section>
  )
}
