import { IconProps } from "@/src/sb/business-layer/types"

const Envelope = (props: IconProps) => (
  <svg
    width={props.width ?? props.size ?? 16}
    height={props.height ?? props.size ?? 16}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6M22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6M22 6L12 13L2 6"
      stroke="currentColor"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Envelope
