import { IconProps } from "@/src/sb/business-layer/types"

const Copy = (props: IconProps) => (
  <svg
    width={props.width ?? props.size ?? 16}
    height={props.height ?? props.size ?? 16}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5M11 9H20C21.1046 9 22 9.89543 22 11V20C22 21.1046 21.1046 22 20 22H11C9.89543 22 9 21.1046 9 20V11C9 9.89543 9.89543 9 11 9Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.3}
      strokeLinejoin="round"
    />
  </svg>
)

export default Copy
