import { useEffect, useState } from "react"

const useVisibilityChange = (): { tabIsVisible: boolean } => {
  const [tabIsVisible, setTabIsVisible] = useState<boolean>(true)

  useEffect((): VoidFunction => {
    const handleVisibilityChange = () => {
      setTabIsVisible(document.visibilityState !== "hidden")
    }

    window.document.addEventListener(
      "visibilitychange",
      handleVisibilityChange,
      false
    )

    return () =>
      window.document.removeEventListener(
        "visibilitychange",
        handleVisibilityChange
      )
  }, [])

  return {
    tabIsVisible
  }
}

export default useVisibilityChange
