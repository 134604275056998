import { RefObject, useEffect } from "react"

export default function useOutsideAlerters(
  ref: RefObject<Array<{ el: HTMLElement | null } | null>>,
  fn: (obj: { el: HTMLElement | null }) => void
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(
      event: MouseEvent,
      obj: { el: HTMLElement | null } | null
    ) {
      if (obj?.el && !obj.el.contains(event.target as any)) {
        fn(obj)
      }
    }
    const len = ref.current?.length
    const listeners: any = []
    for (let i = 0; i < len! || 0; i++) {
      const listener = (e: MouseEvent) => handleClickOutside(e, ref.current![i])
      document.addEventListener("mousedown", listener)
      listeners.push(listener)
    }

    // Bind the event listener
    return () => {
      // Unbind the event listener on clean up
      for (const listener of listeners) {
        document.removeEventListener("mousedown", listener)
      }
    }
  }, [ref])
}
